import React from "react";
import { register } from "swiper/element/bundle";
import "sanitize.css";

import GlobalStyles from "./src/static/styles/global.css";
import FontStyles from "./src/theme/fonts";

export const onClientEntry = () => {
  register(); // Register swiper.js
};

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyles />
    <FontStyles />
    {element}
  </>
);
