import { createGlobalStyle } from "styled-components";

import BonVivantWoff from "src/static/fonts/bonvivant.woff";
import BonVivantWoff2 from "src/static/fonts/bonvivant.woff2";
import MontserratWoff from "src/static/fonts/montserrat.woff";
import MontserratWoff2 from "src/static/fonts/montserrat.woff2";
import MrEavesmodotWoff from "src/static/fonts/mreavesmodot.woff";
import MrEavesmodotWoff2 from "src/static/fonts/mreavesmodot.woff2";
import SilkserifExtraLightItalicWoff from "src/static/fonts/silkserif-extralight-italic.woff";
import SilkserifExtraLightItalicWoff2 from "src/static/fonts/silkserif-extralight-italic.woff2";
import SilkserifExtraLightWoff from "src/static/fonts/silkserif-extralight.woff";
import SilkserifExtraLightWoff2 from "src/static/fonts/silkserif-extralight.woff2";
import SilkserifLightItalicWoff from "src/static/fonts/silkserif-light-italic.woff";
import SilkserifLightItalicWoff2 from "src/static/fonts/silkserif-light-italic.woff2";
import SilkserifLightWoff from "src/static/fonts/silkserif-light.woff";
import SilkserifLightWoff2 from "src/static/fonts/silkserif-light.woff2";
import SilkserifRegularWoff from "src/static/fonts/silkserif-regular.woff";
import SilkserifRegularWoff2 from "src/static/fonts/silkserif-regular.woff2";

const FontStyles = createGlobalStyle`
    @font-face {
        font-family: "Bon Vivant";
        font-style: normal;
        font-weight: 400;
        src: local("Bon Vivant"), url(${BonVivantWoff2}) format("woff2"),
        url(${BonVivantWoff}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        src: url(${MontserratWoff2}) format("woff2"),
        url(${MontserratWoff}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-display: swap;
        font-family: "Mr Eaves";
        font-style: normal;
        font-weight: 400;
        src:    url(${MrEavesmodotWoff2}) format("woff2"),
            url(${MrEavesmodotWoff}) format("woff");
    }

    @font-face {
        font-family: "Silk Serif Extra Light Italic";
        font-style: normal;
        font-weight: 400;
        src:    url(${SilkserifExtraLightItalicWoff2}) format("woff2"),
        url(${SilkserifExtraLightItalicWoff}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-family: "Silk Serif Extra Light";
        font-style: normal;
        font-weight: 400;
        src:    url(${SilkserifExtraLightWoff2}) format("woff2"),
        url(${SilkserifExtraLightWoff}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-family: "Silk Serif Light Italic";
        font-style: normal;
        font-weight: 400;
        src:    url(${SilkserifLightItalicWoff2}) format("woff2"),
        url(${SilkserifLightItalicWoff}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-family: "Silk Serif Light";
        font-style: normal;
        font-weight: 400;
        src:    url(${SilkserifLightWoff2}) format("woff2"),
        url(${SilkserifLightWoff}) format("woff");
        font-display: swap;
    }

    @font-face {
        font-family: "Silk Serif";
        font-style: normal;
        font-weight: 400;
        src:    url(${SilkserifRegularWoff2}) format("woff2"),
        url(${SilkserifRegularWoff}) format("woff");
        font-display: swap;
    }
`;

export default FontStyles;
