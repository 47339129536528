const size = {
  mobile: 375,
  tablet: 768,
  laptopS: 1024,
  laptop: 1440,
  desktop: 1920,
};

export const device = {
  mobileS: `(max-width: ${size.mobile - 1}px)`,
  mobileDOWN: `(max-width: ${size.tablet - 1}px)`,
  mobile: `(min-width: ${size.mobile}px) and (max-width: ${size.tablet - 1}px)`,
  mobileUP: `(min-width: ${size.mobile}px)`,
  tabletDOWN: `(max-width: ${size.laptopS - 1}px)`,
  tablet: `(min-width: ${size.tablet}px) and (max-width: ${
    size.laptopS - 1
  }px)`,
  tabletUP: `(min-width: ${size.tablet}px)`,
  laptopSDOWN: `(max-width: ${size.laptop - 1}px)`,
  laptopS: `(min-width: ${size.laptopS}px) and (max-width: ${
    size.laptop - 1
  }px)`,
  laptopSUP: `(min-width: ${size.laptopS}px)`,
  laptopDOWN: `(max-width: ${size.desktop - 1}px)`,
  laptop: `(min-width: ${size.laptop}px) and (max-width: ${
    size.desktop - 1
  }px)`,
  laptopUP: `(min-width: ${size.laptop}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
};
