import { createGlobalStyle } from "styled-components";
import { device } from "../../theme/breakpoints";

import { colors } from "../../theme/colors";

export default createGlobalStyle`
    body {
        color: ${colors.blue};
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        line-height: 1.6;
        overflow-x: hidden;

        @media ${device.tabletUP} {
            font-size: 16px;
        }
    }
`;
